// css
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import {
  REACT_APP_ENV,
  REACT_APP_MATT_BACKEND_URL,
  REACT_APP_VERSION,
} from "./constants/settings";
import { datadogRum } from "@datadog/browser-rum";

if (REACT_APP_ENV !== "development") {
  datadogRum.init({
    applicationId: "3b6e122d-8793-4dbe-b331-896070f53e55",
    clientToken: "pub98e79cbd42d47d668b8a1f4d8866074c",
    site: "datadoghq.com",
    service: "matt-frontend",
    env: REACT_APP_ENV,
    version: REACT_APP_VERSION,
    sessionSampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: [REACT_APP_MATT_BACKEND_URL],
  });

  datadogRum.startSessionReplayRecording();
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
