import { Container } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { REACT_APP_MATT_BACKEND_URL } from "../constants/settings";
import axios from "axios";
import "./App.css";
import { useState } from "react";

const App = () => {
  const [loading, setLoading] = useState(false);

  const onGetTaxData = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `${REACT_APP_MATT_BACKEND_URL}/api/taxes/user-tax-data`
      );
      console.log(res);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <LoadingButton
        variant="contained"
        disabled={loading}
        loading={loading}
        onClick={onGetTaxData}
      >
        Get tax data
      </LoadingButton>
    </Container>
  );
};

export default App;
